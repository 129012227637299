// src/components/SuccessPage.js
import React from "react";

const SuccessPage = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
			}}
		>
			<h1>Form Submitted Successfully!</h1>
		</div>
	);
};

export default SuccessPage;
