// src/components/SplashPage.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "./FormInput"; // Import the FormInput component
import "./SplashPage.css"; // Import the CSS file

const correctPassword = process.env.REACT_APP_PASSWORD; // Access the password from .env

const SplashPage = () => {
	const [password, setPassword] = useState("");
	const [showForm, setShowForm] = useState(false);
	const [loading, setLoading] = useState(false); // Add loading state
	const [formData, setFormData] = useState({
		first: "",
		last: "",
		email: "",
		phone: "",
		address_1: "",
		address_2: "",
		city: "",
		state: "",
		zip: "",
		country: "",
	});
	const navigate = useNavigate();

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (password === correctPassword) {
			setShowForm(true);
		} else {
			alert("Incorrect password. Please try again.");
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoading(true); // Set loading to true
		console.log("Form Data:", formData); // Debugging log

		try {
			const response = await fetch(
				"https://script.google.com/macros/s/AKfycbyQPshxWFOiq6jV7K2MJkPdywcwsMeS6YBxgjVDPWHhpDPHni4Y74Ge-Dy1mOWKpw_D/exec",
				{
					method: "POST",
					headers: {
						"Content-Type":
							"application/x-www-form-urlencoded",
					},
					body: JSON.stringify({
						first_name: formData.first,
						last_name: formData.last,
						email_address: formData.email,
						phone_number: formData.phone,
						address_1: formData.address_1,
						address_2: formData.address_2,
						city: formData.city,
						state: formData.state,
						zip: formData.zip,
						country: formData.country,
					}),
				}
			);

			const result = await response.json();
			console.log("Response:", result); // Debugging log

			if (result.message === "Success") {
				setLoading(false); // Set loading to false
				navigate("/success"); // Navigate to the success page
			} else {
				setLoading(false); // Set loading to false
				alert(
					"There was an error submitting the form. Please try again."
				);
			}
		} catch (error) {
			console.error("Error:", error);
			setLoading(false); // Set loading to false
			alert(
				"There was an error submitting the form. Please try again."
			);
		}
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
				padding: "20px",
				boxSizing: "border-box",
			}}
		>
			{!showForm ? (
				<>
					<h1>Welcome to My App</h1>
					<form
						onSubmit={handlePasswordSubmit}
						className="password-form"
					>
						<div className="input-container">
							<input
								type="password"
								placeholder="Enter password"
								value={password}
								onChange={(e) =>
									setPassword(
										e
											.target
											.value
									)
								}
								className="password-input"
								required
							/>
							<button
								type="submit"
								className="password-submit"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-arrow-right"
								>
									<line
										x1="5"
										y1="12"
										x2="19"
										y2="12"
									></line>
									<polyline points="12 5 19 12 12 19"></polyline>
								</svg>
							</button>
						</div>
					</form>
				</>
			) : (
				<>
					{loading ? (
						<div>Loading...</div>
					) : (
						<form
							onSubmit={
								handleFormSubmit
							}
							style={{
								width: "100%",
								maxWidth: "600px",
							}}
						>
							<h2>
								Please provide
								your information
							</h2>
							<FormInput
								label="First Name"
								type="text"
								value={
									formData.first
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											first: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="First Name"
							/>
							<FormInput
								label="Last Name"
								type="text"
								value={
									formData.last
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											last: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="Last Name"
							/>
							<FormInput
								label="Email Address"
								type="email"
								value={
									formData.email
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											email: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="Email Address"
							/>
							<FormInput
								label="Phone Number"
								type="tel"
								value={
									formData.phone
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											phone: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="Phone Number"
							/>
							<FormInput
								label="Address 1"
								type="text"
								value={
									formData.address_1
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											address_1: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="Address 1"
							/>
							<FormInput
								label="Address 2"
								type="text"
								value={
									formData.address_2
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											address_2: e
												.target
												.value,
										}
									)
								}
								placeholder="Address 2"
							/>
							<FormInput
								label="City"
								type="text"
								value={
									formData.city
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											city: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="City"
							/>
							<FormInput
								label="State"
								type="text"
								value={
									formData.state
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											state: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="State"
							/>
							<FormInput
								label="ZIP"
								type="text"
								value={
									formData.zip
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											zip: e
												.target
												.value,
										}
									)
								}
								required
								pattern="[0-9]{5}(-[0-9]{4})?" // US ZIP code pattern (5 digits or 5+4)
								placeholder="ZIP"
							/>
							<FormInput
								label="Country"
								type="text"
								value={
									formData.country
								}
								onChange={(e) =>
									setFormData(
										{
											...formData,
											country: e
												.target
												.value,
										}
									)
								}
								required
								placeholder="Country"
							/>
							<button
								type="submit"
								style={{
									marginTop: "10px",
									padding: "10px 20px",
								}}
							>
								Submit
							</button>
						</form>
					)}
				</>
			)}
		</div>
	);
};

export default SplashPage;
